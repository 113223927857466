<template>
  <EntityList
    :config="config"
    :columns="columns"
    :filter-vals.sync="filterVals"
    :filters="filters"
    :formatted-columns="formattedColumns"
    authority="Questionnaire_Management_Questionnaire"
    single-edit
    desc-sort
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import store from '@/store'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      categoryOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'questionnaire',
        endpoint: 'questionnaire',
        route: 'questionnaires',
        title: {
          single: this.$t('Questionnaire Add'),
          plural: this.$t('Questionnaires'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('Title'), sortable: true },
        { key: 'category.title', label: this.$t('Category'), sortable: true },
        { key: 'startDate', label: this.$t('Start Date'), sortable: false },
        { key: 'endDate', label: this.$t('End Date'), sortable: false },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formattedColumns() {
      return [
        { key: 'startDate', type: 'date' },
        { key: 'endDate', type: 'date' },
      ]
    },

    filters() {
      return [
        {
          name: 'category',
          label: this.$t('Category'),
          options: this.categoryOptions,
        },
        {
          name: 'startDate',
          type: 'date',
          label: this.$t('Start Date'),
          maxDateFieldIdx: '2',
        },
        {
          name: 'endDate',
          type: 'date',
          label: this.$t('End Date'),
          minDateFieldIdx: '1',
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('enabled'),
        },
      ]
    },
  },
  mounted() {
    store
      .dispatch('app-common/fetchQuestionnaireCategories')
      .then((response) => {
        this.categoryOptions = response.data
      })
  },
}
</script>
